<template>
    <div class="flex gap-2 mt-4 justify-between">

        <FaceRecordingAudioWavesurfer
            v-if="mounted"
            :audio="audio"
            @play="hidePlayButton = true"
            @pause="hidePlayButton = false"
            class=""
            ref="player"/>


        <button
            :disabled="player?.loading"
            @click.prevent="playPause"
            :class="{
                        '': hidePlayButton,
                        'opacity-25': player?.loading
                    }"
            class="cursor-pointer">

                    <span
                        class="size-12 text-xs flex items-center gap-2 text-primary bg-white rounded-full p-2 border-2 hover:border-black">
                        <PlaySvg v-if="!hidePlayButton"/>
                        <PauseSvg v-else/>
                    </span>
        </button>

        <button
            v-if="showDownloadButton"
            :disabled="player?.loading"
            @click.prevent="downloadRecording"
            :class="{
                        'opacity-25': player?.loading
                    }"
            class="cursor-pointer">

                    <span
                        class="size-12 text-xs flex items-center gap-2 text-black bg-white rounded-full p-2 border-2 hover:border-black">
                        <MdiIcon :icon="mdiDownload" class=""/>
                    </span>
        </button>
    </div>
    <div class="mt-2 flex gap-1">
        <button @click.prevent="currentSpeed = speed"
                v-for="speed in speeds"
                :class="{
                        'bg-primary text-white': currentSpeed === speed,
                        'text-black': currentSpeed !== speed
                }"
                class="text-xs rounded-full border w-8 h-8 hover:opacity-50">
            {{ speed }}x
        </button>
    </div>
</template>
<script setup>
import {ref, onMounted, watch, nextTick} from "vue";
import PlaySvg from "@/Components/PlaySvg.vue";
import PauseSvg from "@/Components/PauseSvg.vue";
import FaceRecordingAudioWavesurfer from "@/Components/Marketing/FacePlayer/FaceRecordingAudioWavesurfer.vue";
import MdiIcon from "@/Components/MdiIcon.vue";
import {mdiDownload} from "@mdi/js";

const props = defineProps({
    audio: {
        type: String,
        required: true
    },
    showDownloadButton: {
        type: Boolean,
        default: false
    },
    defaultSpeed: {
        type: Number,
        default: 1.2
    }
})
const player = ref()
const hidePlayButton = ref(false)
const mounted = ref(false)
const currentSpeed = ref(props.defaultSpeed)

const speeds = [1.0, 1.2, 1.5, 2.0, 2.5]

watch(currentSpeed, (speed) => {
    setSpeed(speed)
})

const playPause = () => {
    player.value.playPause()

    // hidePlayButton.value = !hidePlayButton.value
}

const setSpeed = (speed) => {
    player.value.wavesurfer.setPlaybackRate(speed)
}

const downloadRecording = () => {
    const link = document.createElement('a');
    link.href = props.audio;
    const fileExtension = props.audio.split('.').pop();
    link.download = `recording.${fileExtension}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

onMounted(() => {
    mounted.value = true
    setTimeout(() => {
        setSpeed(1.2)
    }, 500)
})
</script>
